import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby-plugin-react-i18next"

import { getFeaturedImage } from "../../helpers/images"
import useMediaQuery from "../../hooks/useMediaQuery"

import * as styles from "./newsBlock.new.module.css"

const NewsBlock = ({ limitItems = 5, isBlock, pageContext }) => {
  const isMobile = useMediaQuery("(max-width: 1023px)")
  const { i18n } = useTranslation()
  const [visibleItems] = useState(limitItems)
  const [showAll, setShowAll] = useState(false)

  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(
          filter: { status: { eq: "publish" } }
          sort: { order: DESC, fields: date }
        ) {
          nodes {
            title
            link
            settings {
              language
            }
            date(formatString: "DD.MM.YYYY")
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let news = allWpPost?.nodes?.filter(
    news => news?.settings?.language === i18n.language
  )

  if (news && !Array.isArray(news)) {
    news = [news]
  }

  const displayedNews = showAll ? news : news.slice(0, visibleItems)

  const parseDate = dateString => {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ]
    const [day, month, year] = dateString.split(".")
    const yearNow = new Date().getFullYear()

    return `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${
      yearNow.toString() !== year ? year : ""
    }`
  }

  const groupByMonthYear = newsItems => {
    return newsItems.reduce((groups, item) => {
      const [day, month, year] = item.date.split(".")
      const monthYear = `${year}/${month}`
      if (!groups[monthYear]) {
        groups[monthYear] = []
      }
      groups[monthYear].push(item)
      return groups
    }, {})
  }

  const groupedNews = groupByMonthYear(displayedNews || [])

  const firstNewsItem = !isBlock
    ? displayedNews?.length > 0
      ? displayedNews.shift()
      : null
    : ""

  const handleShowMore = () => {
    setShowAll(true)
  }

  return isBlock ? (
    <div className={styles.container}>
      <div className={styles.catalog}>
        {displayedNews?.map((item, index) => (
          <Link
            className={styles.newsItem}
            key={index}
            to={`/news${item.link}`}
          >
            <div className={styles.newsWrapper}>
              <div className={styles.imgWrapper}>{getFeaturedImage(item)}</div>
              <div className={styles.textContainer}>
                <p className={cx(styles.date, "text-minimal")}>
                  {parseDate(item.date)}
                </p>
                <p className={styles.title}>{item.title}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <section className={isMobile ? styles.sectionContainer : "container"}>
      <div className={styles.isNotBlockSection}>
        <h3 className={styles.isNotBlockSectionHeader}>НОВОСТИ</h3>
        <div className={styles.isNotBlockSectionItemBox}>
          <div className={styles.featuredNews}>
            {firstNewsItem && (
              <div className={styles.featuredNews}>
                <div className={styles.groupLastNewsBox}>
                  <span className={styles.line}></span>
                  <p className={styles.groupHeader}>Последние новости</p>
                  <span className={styles.line}></span>
                </div>
                <p className={styles.featuredNewsHeader}>Последние новости</p>
                <Link
                  to={`/news${firstNewsItem.link}`}
                  className={styles.isNotBlockSectionItem}
                >
                  <div className={styles.isNotBlockLastNewsImageWrapper}>
                    {getFeaturedImage(firstNewsItem)}
                  </div>
                  <div className={styles.firstNewsItemBoxInfo}>
                    <p className={cx(styles.date, "text-minimal")}>
                      {parseDate(firstNewsItem.date)}
                    </p>
                    <div className={styles.isNotBlockTextContainer}>
                      <p className={styles.isNotBlockLastNewsTitle}>
                        {firstNewsItem.title}
                      </p>
                    </div>
                  </div>
                </Link>
                <div className={styles.isBlockLastNewsNotSectionLink}>
                  <Link
                    to={`/news${firstNewsItem.link}`}
                    className={styles.isNotSectionLinkBtn}
                  >
                    Подробнее
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className={styles.divider}></div>
          <div className={styles.boxAllNews}>
            {Object.keys(groupedNews).map((monthYear, index) => {
              if (groupedNews[monthYear].length <= 1) {
                return null
              }
              const formattedDate = monthYear.replace("/", " / ")
              return (
                <div key={index}>
                  <div
                    className={`${styles.groupDataBox} ${
                      index === 1 ? "" : styles.mt32
                    } ${!isMobile ? styles.mb32 : styles.mb39}`}
                  >
                    <span className={styles.line}></span>
                    <p className={styles.groupHeader}>{formattedDate}</p>
                    <span className={styles.line}></span>
                  </div>
                  {groupedNews[monthYear]
                    .filter((_, itemIndex) => itemIndex !== 0)
                    .map((item, itemIndex) => (
                      <div className={styles.boxAllNewsItem} key={itemIndex}>
                        <Link
                          to={`/news${item.link}`}
                          className={styles.isNotBlockSectionItem}
                        >
                          <div className={styles.isNotBlockNewsWrapper}>
                            <div
                              className={styles.isNotBlockNewsWrapperItemBox}
                            >
                              <div
                                className={styles.isNotBlockNewsImageWrapper}
                              >
                                {getFeaturedImage(item)}
                              </div>
                              <div
                                className={
                                  styles.isNotBlockNewsImageWrapperDescription
                                }
                              >
                                <p className={cx(styles.date, "text-minimal")}>
                                  {parseDate(item.date)}
                                </p>
                                <div className={styles.isNotBlockTextContainer}>
                                  <p
                                    className={
                                      styles.isNotBlockTextContainerTitle
                                    }
                                  >
                                    {item.title}
                                  </p>
                                </div>
                                <div className={styles.isNotSectionLink}>
                                  <Link
                                    to={`/news${item.link}`}
                                    className={styles.isNotSectionLinkBtn}
                                  >
                                    Подробнее
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.showMoreButtonBox}>
          {!showAll && visibleItems < news.length && (
            <button className={styles.showMoreButton} onClick={handleShowMore}>
              Ещё новости
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default NewsBlock

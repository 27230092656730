// extracted by mini-css-extract-plugin
export var boxAllNews = "newsBlock-new-module--boxAllNews--057f6";
export var boxAllNewsItem = "newsBlock-new-module--boxAllNewsItem--05948";
export var catalog = "newsBlock-new-module--catalog--095f5";
export var container = "newsBlock-new-module--container--66e87";
export var date = "newsBlock-new-module--date--9081b";
export var divider = "newsBlock-new-module--divider--3b6fc";
export var featuredNews = "newsBlock-new-module--featuredNews--85a75";
export var featuredNewsHeader = "newsBlock-new-module--featuredNewsHeader--6a57c";
export var firstNewsItemBoxInfo = "newsBlock-new-module--firstNewsItemBoxInfo--cad12";
export var groupDataBox = "newsBlock-new-module--groupDataBox--b1d63";
export var groupHeader = "newsBlock-new-module--groupHeader--d4bec";
export var groupLastNewsBox = "newsBlock-new-module--groupLastNewsBox--29bf5";
export var imgWrapper = "newsBlock-new-module--img-wrapper--a0ce4";
export var inner = "newsBlock-new-module--inner--3f2b9";
export var innerPadding = "newsBlock-new-module--innerPadding--046ec";
export var isBlockLastNewsNotSectionLink = "newsBlock-new-module--isBlockLastNewsNotSectionLink--e4877";
export var isNotBlockLastNewsImageWrapper = "newsBlock-new-module--isNotBlockLastNewsImageWrapper--9bcc1";
export var isNotBlockLastNewsTitle = "newsBlock-new-module--isNotBlockLastNewsTitle--d4eec";
export var isNotBlockNewsImageWrapper = "newsBlock-new-module--isNotBlockNewsImageWrapper--57cbd";
export var isNotBlockNewsImageWrapperDescription = "newsBlock-new-module--isNotBlockNewsImageWrapperDescription--20ed6";
export var isNotBlockNewsWrapper = "newsBlock-new-module--isNotBlockNewsWrapper--8a5c5";
export var isNotBlockNewsWrapperItemBox = "newsBlock-new-module--isNotBlockNewsWrapperItemBox--cd8da";
export var isNotBlockSection = "newsBlock-new-module--isNotBlockSection--1600e";
export var isNotBlockSectionHeader = "newsBlock-new-module--isNotBlockSectionHeader--a6db7";
export var isNotBlockSectionItem = "newsBlock-new-module--isNotBlockSectionItem--7263b";
export var isNotBlockSectionItemBox = "newsBlock-new-module--isNotBlockSectionItemBox--547a2";
export var isNotBlockTextContainer = "newsBlock-new-module--isNotBlockTextContainer--28e88";
export var isNotBlockTextContainerTitle = "newsBlock-new-module--isNotBlockTextContainerTitle--49021";
export var isNotSectionLink = "newsBlock-new-module--isNotSectionLink--d53f5";
export var isNotSectionLinkBtn = "newsBlock-new-module--isNotSectionLinkBtn--c00e5";
export var lastNewsHeaderMobile = "newsBlock-new-module--lastNewsHeaderMobile--8040b";
export var line = "newsBlock-new-module--line--f4dc8";
export var mb32 = "newsBlock-new-module--mb32--56fe7";
export var mb39 = "newsBlock-new-module--mb39--68b24";
export var mt24 = "newsBlock-new-module--mt24--47806";
export var mt32 = "newsBlock-new-module--mt32--7e608";
export var newsItem = "newsBlock-new-module--news-item--b41f3";
export var newsWrapper = "newsBlock-new-module--news-wrapper--c794f";
export var sectionContainer = "newsBlock-new-module--sectionContainer--4e990";
export var showMoreButton = "newsBlock-new-module--showMoreButton--871e2";
export var showMoreButtonBox = "newsBlock-new-module--showMoreButtonBox--6b5d3";
export var textContainer = "newsBlock-new-module--text-container--ce2ca";
export var title = "newsBlock-new-module--title--472f9";
import React from "react"
import * as styles from "./CallToAction.module.css"
import cx from "classnames"

const CallToAction = () => {
  return (
    <section className={cx(styles.container, "container")}>
      <div className={styles.col}>
        <h2 className={cx(styles.title, styles.col)}>
          Набор инструментов для решения eRetail marketing задач бренда с
          собственным агентским сервисом и IT- платформой
        </h2>
        <a
          target="_blank"
          rel="noreferrer"
          download
          href="https://webadmin.dvgroup.com/wp-content/uploads/2024/06/dvg_2024.pdf"
          className={cx("theme-button", styles.button)}
        >
          Скачать презентацию
        </a>
      </div>
    </section>
  )
}
export default CallToAction

import React from "react"

import ImageBannerMainPage from "../../assets/images/imageBannerMainPage.png"
import * as styles from "./banner.module.css"

const Banner = () => {
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.image} src={ImageBannerMainPage} alt="banner" />
      </div>
      <h1 className={styles.title}>eRetail marketing solution</h1>
    </div>
  )
}
export default Banner
